import React from 'react'
import styled from 'styled-components'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'

// Images
import Location from 'img/location.inline.svg'
import Phone from 'img/phone.inline.svg'
import Email from 'img/email.inline.svg'

// CSS
import { FormCSS } from 'styles/FormStyle'

const Section = styled.div`
  ${FormCSS}

  background-color: ${({ theme }) => theme.color.grey};

  & a:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`

const StyledFormDuuf = styled(FormDuuf)``
interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const FormContact: React.FC<FormContactProps> = ({ fields }) => (
  <Section className="py-5">
    <div className="container py-5">
      <ParseContent content={fields.description || ''} />
      <StyledFormDuuf
        id={1}
        className="mt-5"
        generate={false}
        privacyUrl="/privacyverklaring"
        privacyUrlPlacement="Privacy Voorwaarden"
      >
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="row">
              <div className="col-md-7">
                <FormDuufGroup hideIds={[2, 3]} />
              </div>
              <div className="col-md-5">
                <FormDuufGroup showIds={[2, 3]} />
              </div>
            </div>
            <FormDuufSubmit />
            <div className="row mt-5 py-5">
              <div className="col-sm-6">
                <ParseContent
                  content={fields.data?.description}
                  className="mb-4 mb-sm-0"
                />
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-md-6">
                    <div className="d-flex">
                      <Location className="me-2" />
                      <ParseContent content={fields.data?.location} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center">
                      <Phone className="me-1" />
                      <NavLink to={fields.data?.phone?.url || '#'}>
                        {fields.data?.phone?.title}
                      </NavLink>
                    </div>
                    <div className="d-flex align-items-center">
                      <Email className="me-2" />
                      <NavLink to={fields.data?.email?.url || '#'}>
                        {fields.data?.email?.title}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledFormDuuf>
    </div>
  </Section>
)

export default FormContact
